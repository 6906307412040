// @ts-nocheck
import React, { useContext, useEffect } from "react";
import ListadoCursos from "./ListadoCursos";
import obtenerCursosDatos from "../cursos-datos";
import { useParams, useLocation } from "react-router-dom";
import { tituloCursos } from "../texto";
import IdiomaContext from "../idiomaContext";
import {IDIOMA_ESPAÑOL, IDIOMA_INGLES} from '../constantes';

export default function Cursos() {
  const { filtro } = useParams();
  const { idioma, setIdioma } = useContext(IdiomaContext);
  const query = new URLSearchParams(useLocation().search);

  const obtenerTitulo = () => {
    if (!filtro) {
      return tituloCursos(idioma);
    } else if (filtro === "asp-net-core") {
      return "ASP.NET Core";
    } else if (filtro === "angular") {
      return "Angular";
    } else if (filtro === "react") {
      return "React";
    } else if (filtro === "csharp") {
      return ".NET / C#";
    } else if (filtro === "entity-framework-core"){
      return "Entity Framework Core";
    } else {
      return tituloCursos(idioma);
    }
  };

  useEffect(() => {
    if (!query.get("idioma")) {
      return;
    }

    const idiomaQS = query.get("idioma");
    if (idiomaQS === IDIOMA_ESPAÑOL){
      setIdioma(IDIOMA_ESPAÑOL);
    } else if (idiomaQS === IDIOMA_INGLES){
      setIdioma(IDIOMA_INGLES);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h3>{obtenerTitulo()}</h3>
      <ListadoCursos listado={obtenerCursosDatos().reverse()} filtro={filtro} />
    </>
  );
}
